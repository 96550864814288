import React from 'react'
import CV_FR from '../../assets/nicolas-sanches-varela-fr.pdf'
import CV_EN from '../../assets/nicolas-sanches-varela-en.pdf'
import CV_ES from '../../assets/nicolas-sanches-varela-es.pdf'
import {useTranslation} from "react-i18next";
import i18n from 'i18next'

const CTA = () => {

    const { t } = useTranslation()
    const languageSelected = i18n.language;
    console.log('i18n.language : ', i18n.language);
    let CV = '';

    switch (languageSelected) {
        case 'fr':
            CV = CV_FR;
            break;
        case 'en':
            CV = CV_EN;
            break;
        case 'es':
            CV = CV_ES;
            break;
        default:
            CV = CV_FR;
            break;
    }

    return (
        <div className='cta'>
            <a href={CV} download className='btn'>{t('cta.download')}</a>
            <a href='#contact'  className='btn btn-primary'>{t('cta.talk')}</a>
        </div>
    )
}

export default CTA
